<template>
  <div class="abonnement adjust_width">
    <title_cat>Mon abonnement</title_cat>
    
    <div v-if="$parent.boutique.boutique.stripe_abonnement_id && subscription && subscription.product">
      
      <div class="bloc_abonnement">
        <div class="in">
          <div class="left">
            <div class="title">{{ subscription.product.name }}</div>
            <div class="paiement">Prochain paiement :
              {{ (subscription.subscription.plan.amount / 100) + ((subscription.subscription.plan.amount / 100) * (subscription.subscription.default_tax_rates[0].percentage /100)) }}€ TTC le 
              {{ $dayjs.unix(subscription.subscription.current_period_end).format('DD/MM/YYYY') }}
              <span></span></div>
          </div>
          
          <div class="right">
            <div class="price">{{ subscription.subscription.plan.amount / 100 }}€ HT / mois</div>
            <div class="essai" v-if="subscription.subscription.status === 'trialing'"><div class="tag info">Période d'essai</div></div>
          </div>
        </div>
<!--        <div @click="resiliation_etat = !resiliation_etat" class="plus"><i class="el-icon-plus"></i></div>-->
<!--        <div class="plus_cont" v-if="resiliation_etat">-->
<!--          <p>-->
<!--            <b>Résiliation</b>-->
<!--            Votre abonnement est sans engagement, vous pouvez le résilier à tout moment. Notez bien que la résiliation entraine la désactivation immédiate de votre boutique.-->
<!--          </p>-->
<!--          <button_plus txt="Résilier" txt_load="Résiliation en cours" @success="subscriptions_del" color="orange"></button_plus>-->
<!--        </div>-->
      </div>
    
    </div>
    
    <div class="cont" v-if="!$parent.boutique.boutique.stripe_abonnement_id && pay_start === false">
<!--      <div class="left">-->
<!--        <div class="title">Pourquoi cette offre ?</div>-->
<!--        <p>-->
<!--          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aut dicta fuga harum illo incidunt laudantium natus omnis vero! Cumque debitis eveniet nisi quod sed similique tempore totam, ullam ut.-->
<!--        </p>-->
<!--      </div>-->
      <div class="right">
        <pick_pack :little="true" :bo="true" @success="pay_start = true"></pick_pack>

      </div>
    </div>


    <div v-if="pay_start && !$parent.boutique.boutique.stripe_abonnement_id" class="card_pay">
      <paiement @success="paiement_success" :no_padding="true" :pay_id="pay_id" :client_secret="client_secret" 
                txt_info="Période d'essai, rien ne sera prélevé avant 2 mois.
                Sans engagement, vous pouvez résilier à tout moment."
                :bo="true" :total="total" button_txt="Je m'abonne" button_txt_load="Abonnement en cours"></paiement>
    </div>
  </div>
</template>

<script>
import paiement from "@/components/global/paiement";
import pick_pack from "@/components/global/pick_pack";
export default {
  name: "abonnement",
  data() {
    return {
      pay_start: false,
      
      pay_id: null,
      client_secret: null,
      total: null,
      
      resiliation_etat: false,

      subscription: {},
    }
  },
  components: { paiement, pick_pack },
  methods: {
    paiement_success() {
      this.subscription_check()
      this.$store.dispatch("boutique_bo/sync");
    },
    subscriptions_del() {
      this.$http.post('/bo/stripe/subscriptions_del/', { boutique_id: this.$store.state.user.user.boutique }).then(() => {
        this.subscription = null
        this.$store.dispatch("boutique_bo/sync", this.$store.state.user.user.boutique);
      })
    },
    subscription_check() {
      this.$http.get('/bo/stripe/check_subscription/' + this.$store.state.user.user.boutique).then((subscription) => {
        console.log(subscription)
        this.subscription = subscription.data
        console.log(this.subscription)
      })
    }
  },
  mounted() {
    this.subscription_check()
  }
}
</script>

<style>
  .abonnement .pick_pack .pack { margin: 0!important; }
</style>

<style scoped>

 .cont { display: flex; flex-direction: column;  background: #fff; padding: 16px; margin-bottom: 16px; }
 .cont .left { padding-right: 32px; }
 .cont .left p { font-size: 16px; font-weight: 400; }
 .card_pay { border: 1px solid #eee; padding: 0 24px 16px }
 
 .bloc_abonnement { border: 1px solid #eee; font-size: 16px; margin-bottom: 32px; box-shadow: 0 4px 30px 0 rgba(0,0,0,.05) }
 .bloc_abonnement .in { display: flex; justify-content: space-between; padding: 16px;  }
 .bloc_abonnement .title { font-size: 26px; font-weight: 600; padding-bottom: 16px; }
 .bloc_abonnement .paiement { color: var(--boColor-gris2); font-weight: 400; }
 .bloc_abonnement .right { display: flex; align-items: flex-end; flex-direction: column }
 .bloc_abonnement .price {font-size: 26px; font-weight: 600; margin-bottom: 6px; text-align: right }
 .bloc_abonnement .plus { background: #f5f5f5; display: flex; align-items: center; justify-content: center; height: 30px; cursor: pointer }
 .bloc_abonnement .plus_cont { display: flex; justify-content: space-between; align-items: center; padding: 16px }
 .bloc_abonnement .plus_cont p { padding: 0; margin: 0; font-size: 16px; display: flex; flex-direction: column; line-height: 22px; font-weight: 400; }
 .bloc_abonnement .plus_cont p b { padding-bottom: 6px; }
 .bloc_abonnement .plus_cont .button_plus { flex: 0 0 auto; margin-left: 32px; }


 @media only screen and (min-width: 800px) {
   
   .cont { padding: 0; display: block }
   .card_pay { max-width: 500px; min-width: 500px; border: 1px solid #eee; padding: 0 24px 16px }
   .pick_pack { justify-content: flex-start; }
   
 }

</style>