<template>
  <div class="pick_pack" :data-little="little">

    <div class="pack">
      <div class="top">
        
        <div class="title">Starter</div>
        <div class="price">
          <div class="p">49</div>
          <div class="s">€ HT</div>
          <div class="m">/ mois</div>
        </div>
  
        <div class="desc">Gratuit jusqu'à la réouverture de vos commerces</div>
      </div>

      <div class="features" v-if="!little">

        <div class="feature">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" /></svg>
          Interface intuitive et mobile friendly
        </div>
        <div class="feature">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" /></svg>
          Auto-remplissage via Google
        </div>
        <div class="feature">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" /></svg>
          Suivi des commandes en temps réel
        </div>
        <div class="feature">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" /></svg>
          Commandes illimitées
        </div>
        <div class="feature">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" /></svg>
          Interface mobile pour vos livreurs
        </div>
        <div class="feature">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" /></svg>
          Paiement en ligne avec STRIPE
        </div>
        <div class="feature">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" /></svg>
          Statistiques de votre activité
        </div>
        <div class="feature">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z" /></svg>
          Visibilité instantanée (market boost)
        </div>

      </div>
      
<!--      <div class="asterix">*Offre valable jusqu'au 28 février 2021</div>-->

      <router-link v-if="$store.state.user.logged && bo !== true" tag="button" :to="{ name: 'boutique_creation' }">Je me lance</router-link>
      <router-link v-else-if="bo !== true" @click.native="$store.dispatch('user/beforeLogin', 'boutique_creation')" tag="button" :to="{ name: 'user_login' }">Je me lance</router-link>
      <button v-else-if="bo === true" @click="$emit('success')">Je me lance</button>

    </div>
    
    
  </div>
</template>

<script>
export default {
  name: "pick_pack",
  props: [ 'bo', 'little' ],
}
</script>

<style scoped>

  .pick_pack .asterix { font-size: 12px; margin-top: 16px; color: var(--black-sub); font-weight: 400; }

  .pick_pack { display: flex; justify-content: center; padding-bottom: 45px; }
  .pick_pack .top { display: flex; justify-content: center; align-items: center; flex-direction: column }
  .pick_pack .pack { display: flex; flex-direction: column; align-items: center; padding: 30px 16px 50px 16px; background-color: #fff; width: 340px; 
    box-shadow: rgba(33,33,33,.1) 0 4px 24px 5px; border: 3px solid #fff; border-radius: 10px; position: relative; margin: 0 30px
  }
  .pick_pack .pack .title { font-weight: bold; font-size: 30px; padding-bottom: 20px; color: var(--boRed); text-align: center }
  .pick_pack .pack .price { display: flex; color: var(--orange) }
  .pick_pack .pack .price .p { font-size: 58px }
  .pick_pack .pack .price .s { padding-top: 6px; }
  .pick_pack .pack .price .m { display: flex; height: 82%; align-items: flex-end; font-weight: 400; }
  .pick_pack .pack .desc { padding: 16px 0; text-align: center; font-size: 24px; margin-top: 10px; margin-bottom: 16px; color: var(--boRed) }
  .pick_pack[data-little=true] .pack .desc { margin-bottom: 0; }
  .pick_pack .pack .feature { font-size: 16px; font-weight: 400; padding: 0 6px 16px 6px; display: flex; align-items: center; }
  .pick_pack .pack .feature svg { width: 18px; margin-right: 6px; fill: var(--orange) }

  .pick_pack .pack button { position: absolute; bottom: -24px; left: 50%; transform: translateX(-50%); border-radius: 180px; font-size: 16px; font-weight: 700;
    background: transparent linear-gradient(90deg, #f23f3f 0%, #fb7f2a 100%) 0% 0% no-repeat padding-box; text-transform: uppercase;
  }
  
  .pick_pack .pack.avant { background: transparent linear-gradient(40deg, #f23f3f 0%, #fb7f2a 100%) 0% 0% no-repeat padding-box; color: #fff }
  .pick_pack .pack.avant .title { color: #fff }
  .pick_pack .pack.avant .price { color: #fff }
  .pick_pack .pack.avant .desc { color: #fff }
  .pick_pack .pack.avant .feature svg { fill: #ffdac1 }
  .pick_pack .pack.avant button { background: #fff; color: #222; box-shadow: rgba(33,33,33,.09) 0 4px 24px 5px; }
  
  
  /*.pick_pack .pack.no { background: none; background-color: transparent }*/
</style>
